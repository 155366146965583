@import "~base/homePage";

.home-categories {
    @include media-breakpoint-down(xs) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

.home_page_slot hr {
    border: 0;
}
